import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment-timezone";
// import { MenuAlt4Icon } from "@heroicons/react/outline";
import { InboxIcon } from "@heroicons/react/solid";
import {
  fetchDirectSmsList,
  fetchDirectSmsByDateList,
} from "../utills/network";
import { TIME_ZONES } from "../utills/constant";
import Swal from "sweetalert2";

export default function EmailCampaignList() {
  const [smsList, setSMSList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectTerm, setSelectTerm] = useState("");
  const [dateSelectTerm, setDateSelectTerm] = useState("");

  useEffect(() => {
    // getSMSList();
  }, []);

  const getSMSList = () => {
    fetchDirectSmsList().then((result) => {
      if (result && result.success) {
        setSMSList(result.allSms);
      } else {
        Swal.fire("Alert", result.message, "warning");
      }
    });
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSelectChange = (event) => {
    console.log(event.target.value);
    setSelectTerm(event.target.value);
    console.log(selectTerm);
  };

  const handleDateSelectChange = (event) => {
    console.log(event.target.value);
    setSMSList([]);
    const selectedDate = moment(event.target.value, "YYYY-MM-DD").toISOString();
    setDateSelectTerm(event.target.value);
    fetchDirectSmsByDateList(selectedDate).then((result) => {
      console.log(result);
      if (result && result.success) {
        setSMSList(result.allSms);
      } else {
        Swal.fire("Alert", result.message, "warning");
      }
    });
  };

  return (
    <div className="p-4  space-y-4 flex-1 overflow-y-auto">
      <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
        <div className="sm:flex items-center space-y-2 sm:space-y-0 sm:space-x-2">
          <div className="relative">
            <input
              type="text"
              className="w-64 text-sm rounded"
              placeholder="Search ..."
              onChange={handleChange}
            />
          </div>

          <div className="relative hidden">
            <select
              className="w-64 text-sm rounded"
              onChange={handleSelectChange}
            >
              {TIME_ZONES.map((item) => (
                <option key={Object.keys(item)} value={Object.values(item)}>
                  {Object.keys(item)}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <div className=" ">
            <input
              type="date"
              name="demoTime"
              id="demoTime"
              className="w-64 text-sm rounded"
              onChange={handleDateSelectChange}
            />
          </div>
          <div>
            <Link to="/new-email-campaign">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
              >
                <InboxIcon className="-ml-1 mr-2 h-5 w-5" />
                New Email Campaign
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border border-gray-100 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-100">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Company Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Message Body
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Transporter Type
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Status
                    </th>
                    {/* <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Action</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {smsList
                    .filter(
                      (item) =>
                        item.client.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.client.email
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        item.client.phoneNumber
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      //      &&
                      // item.client.companyName
                      //   .toLowerCase()
                      //   .includes(selectTerm.toLowerCase())
                    )
                    .map((sms) => (
                      <tr key={sms._id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-xs font-medium text-gray-900">
                            {sms.client.name}
                          </div>
                          <div className="text-xs text-gray-500">
                            {sms.client.phoneNumber}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {sms.client.email}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                          {sms.client.companyName}
                        </td>
                        <td className="py-4 flex w-72 flex-shrink-0">
                          <div className="text-xs text-gray-500">
                            {sms.body}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 capitalize">
                          {sms.transporter}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 capitalize">
                          {sms.status}
                        </td>
                        {/* <td className="px-6 py-4 whitespace-nowrap text-xs font-medium">
                          <a
                            href="/#"
                            className="text-red-600 hover:text-red-900"
                          >
                            Discard
                          </a>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
