import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import {
  fetchSMSConfigrationsList,
  saveParsedSmsList
} from "../utills/network";

import {
  ArrowLeftIcon,
  CheckIcon,
  MenuAlt4Icon,
} from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { sendDirectSms } from "../utills/network";

export default function MessageSMSForm() {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();
  const [selectedFormat, setSelectedFormat] = useState("");
  const [selectedNetworkCarrier, setSelectedNetworkCarrier] = useState("");
  const [smsConfigurations, setSMSConfigurations] = useState([]);

  useEffect(() => {
    getSMSTemplates();
  }, []);


  const sendNormalSMS = (data) => {

    const payload = {
      client: {
        name: data.clientName,
        phoneNumber: data.phoneNumber,
      },
      body: data.body,
      carrier: data.carrier
    };

    sendDirectSms(payload).then((res) => {
      if (res && res.success) {
        Swal.fire("Alert", res.message, "success");
        setSelectedFormat(null);
        reset();
      } else {
        Swal.fire("Alert", res.message, "warning");
      }
    });

  }

  const sendSpecificBodySMS = (data) => {
    saveParsedSmsList({ format: selectedFormat, passengers: [[data.clientName, data.phoneNumber, '', '']] }).then((result) => {
      if (result && result.success) {
        Swal.fire("Alert", result.message, "success");
        setSelectedFormat(null);
        reset();
      } else {
        Swal.fire("Alert", result.message, "warning");
      }
    });
  }

  const onSubmit = (data) => {
    console.log('hello',{selectedFormat}, {data} )
    if (selectedFormat && selectedFormat !== '') {
      sendSpecificBodySMS(data);
    } else {
      sendNormalSMS(data);
    }
  };

  const getSMSTemplates = () => {
    fetchSMSConfigrationsList().then((result) => {
      if (result && result.success) {
        setSMSConfigurations(result.allSMSConfigrations);
      } else {
        Swal.fire("Alert", result.message, "warning");
      }
    });
  };


  const handleSelectChange = (event) => {
    console.log(event.target.value);
    const object = smsConfigurations.find((data) => data._id === event.target.value);
    if (object) {
      setSelectedFormat(object);
      console.log("if", object);
    } else {
      setSelectedFormat(null);
      console.log("else", object);
    }
  };

 



  return (
    <main className="flex flex-1 flex-col w-full overflow-hidden">
      <div className="h-12 flex-shrink-0 border-b flex justify-between items-center w-full bg-white">
        <div className="font-medium text-lg text-gray-900 tracking-wide px-4 sm:px-8">
          New Message
        </div>
        <div className="h-full lg:hidden">
          <button className="h-full flex justify-center items-center w-12 focus:outline-none outline-none hover:bg-gray-200">
            <MenuAlt4Icon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="p-4  space-y-4 flex-1 flex-col overflow-y-auto">
        <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
          <div>
            <Link to="/">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white focus:outline-none"
              >
                <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5" />
                Back
              </button>
            </Link>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="divide-y">
            <div className="flex flex-wrap max-w-6xl py-8">
              <div className="w-full lg:w-1/3">
                <div className="text-lg font-medium mb-4">SMS Details</div>
              </div>
              <div className="w-full lg:w-2/3 space-y-8">
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 sm:col-span-6">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="phoneNumber"
                        className="block text-sm text-gray-500"
                      >
                        Phone Number
                      </label>
                      <input
                        type="number"
                        name="phoneNumber"
                        id="phoneNumber"
                        className={`${errors.phoneNumber ? "ring-2 ring-red-500" : ""
                          } w-full text-sm rounded`}
                        {...register("phoneNumber", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="clientName"
                        className="block text-sm text-gray-500"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="clientName"
                        id="clientName"
                        className={`${errors.clientName ? "ring-2 ring-red-500" : ""
                          } w-full text-sm rounded`}
                        {...register("clientName", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 ">
                    <div className="relative space-y-1">
                      <select
                        name="smsBody"
                        id="smsBody"
                        className={`${errors.carrier ? "ring-2 ring-red-500" : ""
                      } w-full text-sm rounded`}
                       {...register("carrier",  {
                        required: "Please select a network carrier",
                        validate: value => value !== "" || "Please select a network carrier",
                      })}
                      >
                        <option key="" value=""> Select Network Carriers</option>
                        <option key="vonage" value="vonage">Vonage NEMT</option>
                        <option key="taxi-vonage" value="taxi-vonage">Vonage Taxi</option>
                        <option key="twilio" value="twilio">Twilio</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-12 ">
                    <div className="relative space-y-1">
                      <select
                        name="smsBody"
                        id="smsBody"
                        className={`w-full text-sm rounded`}
                        onChange={handleSelectChange}
                      >
                        <option key='Select SMS Body Format' value=''>
                          Select SMS Body Format
                        </option>
                        
                        {smsConfigurations && smsConfigurations.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {!selectedFormat && <div className="col-span-12 ">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="body"
                        className="block text-sm text-gray-500"
                      >
                        Body
                      </label>
                      <textarea
                        type="text"
                        name="body"
                        id="body"
                        className={`${errors.body ? "ring-2 ring-red-500" : ""
                          } h-24 w-full text-sm resize-none rounded`}
                        {...register("body", { required: true })}
                      ></textarea>
                    </div>
                  </div>}
                </div>
              </div>
            </div>

            <div className="flex flex-wrap max-w-6xl py-8">
              <div className="w-full lg:w-1/3" />
              <div className="w-full lg:w-2/3">
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12">
                    <div className="flex items-center space-x-4">
                      <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                      >
                        <CheckIcon className="-ml-1 mr-2 h-5 w-5" />
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
