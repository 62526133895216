import { NavLink } from "react-router-dom";
import { HomeIcon, LogoutIcon, XIcon, CogIcon } from "@heroicons/react/outline";
import { DocumentSearchIcon, InboxIcon,TemplateIcon, MailIcon, ClockIcon,ChatIcon, SpeakerphoneIcon } from "@heroicons/react/solid";
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

export default function Navbar({ isShowNav, setToken, handleToggleClick }) {
      // extract pathname from location
      const { pathname } = useLocation();

  const handleLogoutClick = () => {
    Swal.fire({
      title: "Alert!",
      text: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#323484",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setToken(null);
      }
    });
  };

  return (
    <nav
      className={`${
        isShowNav ? "" : "hidden"
      } w-64 border-r h-full bg-theme1 divide-y divide-gray-50 divide-opacity-20 lg:flex flex-col z-50  flex-shrink-0 overflow-hidden fixed lg:static`}
    >
      <div className="h-16 flex items-center px-4">
        <div className="flex justify-between items-center w-full ">
          <div className="flex items-center">
            <img
              src="/logo.png"
              // src="https://assets.website-files.com/5e7adffc6e755a6bb3a7d516/5e7adffc6e755a0b85a7d5ad_logo-placeholder-04.svg"
              className="h-8 filter invert-0 "
              alt="Logo"
            />
            <p className="ml-3 text-white">SMS Panel</p>
          </div>
          <div className="lg:hidden">
            <button className="p-1 flex items-center justify-center rounded outline-none focus:outline-none" onClick={handleToggleClick}>
              <XIcon className="h-6 w-6 text-white" />
            </button>
          </div>
        </div>
      </div>
      <div className="px-4 py-8 overflow-y-auto flex flex-col flex-1 justify-between">
        <ul className="space-y-3">
          {/* <li>
            <NavLink
              exact
              to="/dashboard"
              isActive={() => ['/dashboard'].includes(pathname)}
              className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer"
              activeClassName="nav-item-active"
            >
              <div>
                <ChatIcon className="h-5 w-5" />
              </div>
              <div className="font-medium text-sm">Dashboard</div>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              exact
              to="/"
              isActive={() => ['/','/direct-message', '/new-direct-message'].includes(pathname)}
              className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer"
              activeClassName="nav-item-active"
            >
              <div>
                <ChatIcon className="h-5 w-5" />
              </div>
              <div className="font-medium text-sm">Direct Message</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/direct-email"
              isActive={() => ['/direct-email', '/new-direct-email'].includes(pathname)}
              activeClassName="nav-item-active"
              className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer"
            >
              <div>
                <MailIcon className="h-5 w-5" />
              </div>
              <div className="font-medium text-sm">Direct Email</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/email-campaigns"
              isActive={() => ['/email-campaigns', '/new-email-campaign'].includes(pathname)}
              className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer"
              activeClassName="nav-item-active"
            >
              <div>
                <SpeakerphoneIcon className="h-5 w-5" />
              </div>
              <div className="font-medium text-sm">Email Campaign</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/schedule-message"
              isActive={() => ['/schedule-message','/new-schedule-message'].includes(pathname)}
              activeClassName="nav-item-active"
              className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer"
            >
              <div>
                <ClockIcon className="h-5 w-5" />
              </div>
              <div className="font-medium text-sm">Schedule Messages</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/messages-logs"
              className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer"
              activeClassName="nav-item-active"
            >
              <div>
                <DocumentSearchIcon className="h-5 w-5" />
              </div>
              <div className="font-medium text-sm">Messages Logs</div>
            </NavLink>
          </li>
        </ul>
        <ul className="space-y-3 mt-3">
        <li>
            <NavLink
              exact
              to="/sms-configurations-list"
              isActive={() => ['/sms-configurations-list', '/sms-email-configurations'].includes(pathname)}
              className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer"
              activeClassName="nav-item-active"
            >
              <div>
                <TemplateIcon className="h-5 w-5" />
              </div>
              <div className="font-medium text-sm">Message Configurations</div>
            </NavLink>
          </li>  <li>
            <NavLink
              exact
              to="/email-configurations-list"
              isActive={() => ['/email-configurations-list', '/new-email-configurations'].includes(pathname)}
              className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer"
              activeClassName="nav-item-active"
            >
              <div>
                <TemplateIcon className="h-5 w-5" />
              </div>
              <div className="font-medium text-sm">Email Configurations</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/profile"
              activeClassName="nav-item-active"
              className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer"
            >
              <div>
                <CogIcon className="h-5 w-5" />
              </div>
              <div className="font-medium text-sm">Settings</div>
            </NavLink>
          </li>
          <li onClick={() => handleLogoutClick()}>
            <div className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer">
              <div>
                <LogoutIcon className="h-5 w-5" />
              </div>
              <div className="font-medium text-sm">Log Out</div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}
