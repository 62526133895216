import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";

import {
  ArrowLeftIcon,
  CheckIcon,
  MenuAlt4Icon,
} from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { TIME_ZONES } from "../utills/constant";
import { sendDirectEmail } from "../utills/network";

export default function EmailForm() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    sendDirectEmail(data).then((res) => {
      if (res && res.success) {
        Swal.fire("Alert", res.message, "success");
        reset();
      } else {
        Swal.fire("Alert", res.message, "warning");
      }
    });
  };

  return (
    <main className="flex flex-1 flex-col w-full overflow-hidden">
      <div className="h-12 flex-shrink-0 border-b flex justify-between items-center w-full bg-white">
        <div className="font-medium text-lg text-gray-900 tracking-wide px-4 sm:px-8">
          New Email
        </div>
        <div className="h-full lg:hidden">
          <button className="h-full flex justify-center items-center w-12 focus:outline-none outline-none hover:bg-gray-200">
            <MenuAlt4Icon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="p-4  space-y-4 flex-1 flex-col overflow-y-auto">
        <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
          <div>
            <Link to="/direct-email">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white focus:outline-none"
              >
                <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5" />
                Back
              </button>
            </Link>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="divide-y">
            <div className="flex flex-wrap max-w-5xl py-8">
              <div className="w-full lg:w-1/3">
                <div className="text-lg font-medium mb-4">Email Details</div>
              </div>
              <div className="w-full lg:w-2/3">
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 sm:col-span-6">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="to"
                        className="block text-sm text-gray-500"
                      >
                        To
                      </label>
                      <input
                        type="text"
                        name="to"
                        id="to"
                        className={`${
                          errors.to ? "ring-2 ring-red-500" : ""
                        } w-full text-sm rounded`}
                        {...register("to", {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
                        })}
                      />
                    </div>
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="subject"
                        className="block text-sm text-gray-500"
                      >
                        Subject
                      </label>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        className={`${
                          errors.subject ? "ring-2 ring-red-500" : ""
                        } w-full text-sm rounded`}
                        {...register("subject", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 ">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="body"
                        className="block text-sm text-gray-500"
                      >
                        Body
                      </label>
                      <textarea
                        type="text"
                        name="body"
                        id="body"
                        className={`${
                          errors.body ? "ring-2 ring-red-500" : ""
                        } h-24 w-full text-sm resize-none rounded`}
                        {...register("body", { required: true })}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap max-w-5xl py-8">
              <div className="w-full lg:w-1/3" />
              <div className="w-full lg:w-2/3">
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12">
                    <div className="flex items-center space-x-4">
                      <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                      >
                        <CheckIcon className="-ml-1 mr-2 h-5 w-5" />
                        Send Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
