const { SERVER_URL } = require("./constant");

const fetchSmsList = () => {
  return fetch(`${SERVER_URL}/scheduleSms`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get SMS List Error", error));
};
const fetchEmailsList = () => {
  return fetch(`${SERVER_URL}/email`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Email List Error", error));
};

const fetchEmailsListByDate = (selectedDate) => {
  return fetch(
    `${SERVER_URL}/email/byDate?` +
    new URLSearchParams({
      selectedDate,
    }),
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  )
    .then((res) => res.json())
    .catch((error) => console.log("Get SMS List Error", error));
};

const fetchSmsByDateList = (selectedDate) => {
  return fetch(
    `${SERVER_URL}/scheduleSms/byDate?` +
    new URLSearchParams({
      selectedDate,
    }),
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  )
    .then((res) => res.json())
    .catch((error) => console.log("Get SMS List Error", error));
};

const fetchProviderSmsList = () => {
  return fetch(`${SERVER_URL}/provider/notifications`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get SMS List Error", error));
};
const fetchProviderSmsByDateList = (selectedDate) => {
  return fetch(
    `${SERVER_URL}/provider/notifications/date?` +
    new URLSearchParams({
      selectedDate,
    }),
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  )
    .then((res) => res.json())
    .catch((error) => console.log("Get SMS List Error", error));
};

const scheduleSms = (payload) => {
  return fetch(`${SERVER_URL}/scheduleSms`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Schedule SMS  Error", error));
};

const sendDirectSms = (payload) => {
  return fetch(`${SERVER_URL}/directSms`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Direct SMS Send  Error", error));
};


const sendEmailCampaign = (payload) => {
  return fetch(`${SERVER_URL}/emailCampaign`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Direct SMS Send  Error", error));
};

const sendDirectEmail = (payload) => {
  return fetch(`${SERVER_URL}/email`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Direct SMS Send  Error", error));
};

const updateSmsStatus = (payload) => {
  return fetch(`${SERVER_URL}/scheduleSms`, {
    method: "put",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Update Direct SMS Send  Error", error));
};

const fetchDirectSmsList = () => {
  return fetch(`${SERVER_URL}/directSms`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get SMS List Error", error));
};

const fetchDirectSmsByDateList = (selectedDate) => {
  return fetch(
    `${SERVER_URL}/directSms/byDate?` +
    new URLSearchParams({
      selectedDate,
    }),
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  )
    .then((res) => res.json())
    .catch((error) => console.log("Get SMS List Error", error));
};

const fetchBatteryLevel = (email) => {
  return fetch(
    `${SERVER_URL}/users/batteryLevel?` +
    new URLSearchParams({
      email,
    }),
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  )
    .then((res) => res.json())
    .catch((error) => console.log("Get Battery Level Error", error));
};

const signin = (payload) => {
  return fetch(`${SERVER_URL}/auth/signin`, {
    method: "post",
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Signin  Error", error));
};

const changePassword = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  };

  return fetch(`${SERVER_URL}/changePassword`, requestOptions)
    .then((response) => response.json())
    .catch((err) => {
      console.log("Change Password Error ", err);
    });
};

const updateConstants = (payload) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ _id: "614c723a3b8ec73f0df453ef", ...payload }),
  };

  return fetch(`${SERVER_URL}/constant`, requestOptions)
    .then((response) => response.json())
    .catch((err) => {
      console.log("update Constants Error ", err);
    });
};
const fetchConstantsList = () => {
  return fetch(`${SERVER_URL}/constant`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get Constants List Error", error));
};
const fetchConstantsLatestList = () => {
  return fetch(`${SERVER_URL}/constant/latest`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get Constants List Error", error));
};

const saveConstants = (payload) => {
  return fetch(`${SERVER_URL}/constant`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Direct SMS Send  Error", error));
};


// new

const updateConstants3 = (payload) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ _id: "614c723a3b8ec73f0df453ef", ...payload }),
  };

  return fetch(`${SERVER_URL}/constantTwo`, requestOptions)
    .then((response) => response.json())
    .catch((err) => {
      console.log("update Constants Error ", err);
    });
};
const fetchConstantsList3 = () => {
  return fetch(`${SERVER_URL}/constantTwo`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get Constants List Error", error));
};
const fetchConstantsLatestList3 = () => {
  return fetch(`${SERVER_URL}/constantTwo/latest`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get Constants List Error", error));
};


const fetchConstantsLatestList4 = () => {
  return fetch(`${SERVER_URL}/constantThree/latest`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get Constants List Error", error));
};

const saveConstants3 = (payload) => {
  return fetch(`${SERVER_URL}/constantTwo`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Direct SMS Send  Error", error));
};

const saveConstants4 = (payload) => {
  return fetch(`${SERVER_URL}/constantThree`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Direct SMS Send  Error", error));
};

const validateAuthToken = () => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return fetch(`${SERVER_URL}/users/me`, requestOptions)
    .then((response) => response.json())
    .catch((err) => {
      console.log("Me Request Error ", err);
    });
};

const fetchEmailConfigrationsList = () => {
  return fetch(`${SERVER_URL}/email-configrations`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Email Configrations List Error", error));
};
const fetchSMSConfigrationsList = () => {
  return fetch(`${SERVER_URL}/smsConfigrations`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("SMS Configrations List Error", error));
};
const saveEmailConfigrationsList = (payload) => {
  return fetch(`${SERVER_URL}/email-configrations`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Email Configrations Save  Error", error));
};

const saveSMSConfigrationsList = (payload) => {
  return fetch(`${SERVER_URL}/smsConfigrations`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("SMS Configrations Save  Error", error));
};

const updateSMSConfigrationsList = (id,payload) => {
  return fetch(`${SERVER_URL}/smsConfigrations/${id}`, {
    method: "put",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("SMS Configrations Save  Error", error));
};

const fetchSMSConfigrationsById = (id) => {
  return fetch(`${SERVER_URL}/smsConfigrations/${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("SMS Configrations By Id Error", error));
};

const saveParsedSmsList = (payload) => {
  return fetch(`${SERVER_URL}/directSms/parser`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Sms Parser List Save Error", error));
};

export {
  signin,
  fetchSmsList,
  scheduleSms,
  changePassword,
  updateConstants,
  validateAuthToken,
  fetchProviderSmsList,
  fetchConstantsList,
  fetchProviderSmsByDateList,
  sendDirectSms,
  fetchDirectSmsList,
  updateSmsStatus,
  fetchSmsByDateList,
  fetchDirectSmsByDateList,
  saveConstants,
  fetchConstantsLatestList,
  fetchEmailsList,
  fetchEmailsListByDate,
  sendDirectEmail,
  fetchEmailConfigrationsList,
  saveEmailConfigrationsList,
  sendEmailCampaign,
  saveParsedSmsList,
  saveSMSConfigrationsList,
  fetchSMSConfigrationsList,
  fetchBatteryLevel,
  fetchSMSConfigrationsById,
  updateSMSConfigrationsList,
  saveConstants3,
  updateConstants3,
  fetchConstantsLatestList3,
  fetchConstantsList3,
  saveConstants4,
  fetchConstantsLatestList4
};
