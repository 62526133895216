import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment-timezone";
// import { MenuAlt4Icon } from "@heroicons/react/outline";
import { InboxIcon } from "@heroicons/react/solid";
import { fetchSMSConfigrationsList } from "../utills/network";
import { TIME_ZONES } from "../utills/constant";
import Swal from "sweetalert2";

export default function SmsConfigurationsList() {
    const [smsConfigrations, setSMSConfigrations] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        getSMSList();
    }, []);

    const getSMSList = () => {
        fetchSMSConfigrationsList().then((result) => {
            if (result && result.success) {
                setSMSConfigrations(result.allSMSConfigrations);
            } else {
                Swal.fire("Alert", result.message, "warning");
            }
        });
    };


    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className="p-4  space-y-4 flex-1 overflow-y-auto">
            <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
                <div className="sm:flex items-center space-y-2 sm:space-y-0 sm:space-x-2">
                    <div className="relative">
                        <input
                            type="text"
                            className="w-64 text-sm rounded"
                            placeholder="Search ..."
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="flex items-center space-x-2">
                    <div>
                        <Link to="/new-sms-configurations">
                            <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                            >
                                <InboxIcon className="-ml-1 mr-2 h-5 w-5" />
                                New SMS Body
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full">
                        <div className="shadow overflow-hidden border border-gray-100 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-100">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                        >
                                            Title
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                        >
                                            Body
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                        >
                                            Edit
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {smsConfigrations.map((sms) => (
                                        <tr key={sms._id}>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">
                                                    {sms.title}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className="has-tooltip">
                                                    {/* <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                            {sms.body}
                          </span> */}
                                                    <div className="text-xs text-gray-900 flex w-full flex-shrink-0 line-clamp-4">
                                                        {sms.body}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <Link to={`/new-sms-configurations/${sms._id}`}>
                                                    <svg className="w-4 h-4 text-theme1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
