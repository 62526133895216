import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment-timezone";
// import { MenuAlt4Icon } from "@heroicons/react/outline";
import { InboxIcon } from "@heroicons/react/solid";
import { fetchEmailConfigrationsList } from "../utills/network";
import { TIME_ZONES } from "../utills/constant";
import Swal from "sweetalert2";

export default function EmailConfigurationsList() {
  const [emailConfigrations, setEmailConfigrations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getEmailsList();
  }, []);

  const getEmailsList = () => {
    fetchEmailConfigrationsList().then((result) => {
      if (result && result.success) {
        setEmailConfigrations(result.allEmails);
      } else {
        Swal.fire("Alert", result.message, "warning");
      }
    });
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="p-4  space-y-4 flex-1 overflow-y-auto">
      <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
        <div className="sm:flex items-center space-y-2 sm:space-y-0 sm:space-x-2">
          <div className="relative">
            <input
              type="text"
              className="w-64 text-sm rounded"
              placeholder="Search ..."
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <div>
            <Link to="/new-email-configurations">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
              >
                <InboxIcon className="-ml-1 mr-2 h-5 w-5" />
                New Configurations
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border border-gray-100 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-100">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Subject
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      From
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      BCC
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Body
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Sender
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {emailConfigrations.map((email) => (
                    <tr key={email._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {email.title}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {email.subject}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {email.from}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{email.bcc}</div>
                      </td>
                      <td className="px-6 py-4">
                        {/* <div className="text-xs text-gray-900 flex w-80 flex-shrink-0 line-clamp-4">
                          {email.body}
                        </div> */}

                        <div class="has-tooltip">
                          <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                            {email.body}
                          </span>
                          <div className="text-xs text-gray-900 flex w-80 flex-shrink-0 line-clamp-4">
                            {email.body}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{email.sender}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <Moment format="YYYY-MM-DD HH:mm">
                          {email.createdAt}
                        </Moment>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
