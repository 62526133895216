import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";

import {
  ArrowLeftIcon,
  CheckIcon,
  MenuAlt4Icon,
} from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { TIME_ZONES } from "../utills/constant";
import { scheduleSms } from "../utills/network";

export default function MessageForm() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const validateReminder = async () => {
    const values = getValues();
    return (
      values.isBefore15Mins || values.isBefore2Hours || values.isBefore24Hours
    );
  };

  const onSubmit = (data) => {
    console.log(data);
    const payload = {
      client: {
        name: data.clientName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        companyName: data.companyName,
      },
      schedule: {
        date: moment(data.demoTime).tz(data.timeZone).toISOString(),
        timeZone: data.timeZone,
      },
      isBefore15Mins: data.isBefore15Mins,
      isBefore2Hours: data.isBefore2Hours,
      isBefore24Hours: data.isBefore24Hours,
    };

    scheduleSms(payload).then((res) => {
      if (res && res.success) {
        Swal.fire('Alert', res.message , 'success')
        reset();
      }else{
        Swal.fire("Alert", res.message, "warning");
      }
    });
  };

  return (
    <main className="flex flex-1 flex-col w-full overflow-hidden">
      <div className="h-12 flex-shrink-0 border-b flex justify-between items-center w-full bg-white">
        <div className="font-medium text-lg text-gray-900 tracking-wide px-4 sm:px-8">
          Schedule Message
        </div>
        <div className="h-full lg:hidden">
          <button className="h-full flex justify-center items-center w-12 focus:outline-none outline-none hover:bg-gray-200">
            <MenuAlt4Icon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="p-4  space-y-4 flex-1 flex-col overflow-y-auto">
        <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
          <div>
            <Link to="/schedule-message">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-gray-500 bg-white focus:outline-none"
              >
                <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5" />
                Back
              </button>
            </Link>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="divide-y">
            <div className="flex flex-wrap max-w-5xl py-8">
              <div className="w-full lg:w-1/3">
                <div className="text-lg font-medium mb-4">Personal Details</div>
              </div>
              <div className="w-full lg:w-2/3">
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 sm:col-span-6">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="clientName"
                        className="block text-sm text-gray-500"
                      >
                        Client Name
                      </label>
                      <input
                        name="clientName"
                        type="text"
                        id="clientName"
                        className={`${
                          errors.clientName ? "ring-2 ring-red-500" : ""
                        } w-full text-sm rounded`}
                        {...register("clientName", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="phoneNumber"
                        className="block text-sm text-gray-500"
                      >
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        id="phoneNumber"
                        className={`${
                          errors.phoneNumber ? "ring-2 ring-red-500" : ""
                        } w-full text-sm rounded`}
                        {...register("phoneNumber", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="email"
                        className="block text-sm text-gray-500"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className={`${
                          errors.email ? "ring-2 ring-red-500" : ""
                        } w-full text-sm rounded`}
                        {...register("email", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="companyName"
                        className="block text-sm text-gray-500"
                      >
                        Company Name
                      </label>
                      <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        className={`${
                          errors.companyName ? "ring-2 ring-red-500" : ""
                        } w-full text-sm rounded`}
                        {...register("companyName", { required: true })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap max-w-5xl py-8">
              <div className="w-full lg:w-1/3">
                <div className="text-lg font-medium mb-4">Demo Details</div>
              </div>
              <div className="w-full lg:w-2/3">
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 sm:col-span-6">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="demoTime"
                        className="block text-sm text-gray-500"
                      >
                        Demo Time
                      </label>
                      <input
                        type="datetime-local"
                        name="demoTime"
                        id="demoTime"
                        className={`${
                          errors.demoTime ? "ring-2 ring-red-500" : ""
                        } w-full text-sm rounded`}
                        {...register("demoTime", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <div className="relative space-y-1">
                      <label
                        htmlFor="timeZone"
                        className="block text-sm text-gray-500"
                      >
                        Time Zone
                      </label>
                      <select
                        name="timeZone"
                        id="timeZone"
                        className={`${
                          errors.timeZone ? "ring-2 ring-red-500" : ""
                        } w-full text-sm rounded`}
                        {...register("timeZone", { required: true })}
                      >
                        {TIME_ZONES.map((item) => (
                          <option
                            key={Object.keys(item)}
                            value={Object.values(item)}
                          >
                            {Object.keys(item)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap max-w-5xl py-8">
              <div className="w-full lg:w-1/3">
                <div className="text-lg font-medium mb-4">Reminders</div>
              </div>
              <div className="w-full lg:w-2/3">
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12">
                    <div className="flex items-center space-x-4">
                      <input
                        type="checkbox"
                        name="isBefore24Hours"
                        id="isBefore24Hours"
                        className={`${
                          errors.isBefore24Hours ? "ring-2 ring-red-500" : ""
                        } 
                        rounded`}
                        {...register("isBefore24Hours", {
                          required: false,
                          validate: validateReminder,
                        })}
                      />
                      <label
                        htmlFor="isBefore24Hours"
                        className="text-sm text-gray-500"
                      >
                        24 hours before meeting
                      </label>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="flex items-center space-x-4">
                      <input
                        type="checkbox"
                        name="isBefore2Hours"
                        id="isBefore2Hours"
                        className={`${
                          errors.isBefore2Hours ? "ring-2 ring-red-500" : ""
                        } 
                        rounded`}
                        {...register("isBefore2Hours", {
                          required: false,
                          validate: validateReminder,
                        })}
                      />
                      <label
                        htmlFor="isBefore2Hours"
                        className="text-sm text-gray-500"
                      >
                        2 hours before meeting
                      </label>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="flex items-center space-x-4">
                      <input
                        type="checkbox"
                        name="isBefore15Mins"
                        className={`${
                          errors.isBefore15Mins ? "ring-2 ring-red-500" : ""
                        } 
                        rounded`}
                        id="isBefore15Mins"
                        {...register("isBefore15Mins", {
                          required: false,
                          validate: validateReminder,
                        })}
                      />
                      <label
                        htmlFor="isBefore15Mins"
                        className="text-sm text-gray-500"
                      >
                        15 minutes before meeting
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap max-w-5xl py-8">
              <div className="w-full lg:w-1/3" />
              <div className="w-full lg:w-2/3">
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12">
                    <div className="flex items-center space-x-4">
                      <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                      >
                        <CheckIcon className="-ml-1 mr-2 h-5 w-5" />
                        Schedule Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
