import React, { useState, useEffect } from "react";

const ImageViewerModal = ({ imageViewerModal, setImageViewerModal }) => {

  useEffect(() => {
    // getSMSList();
  }, []);

  return (
    <div class="relative z-100" aria-labelledby="modal-title" role="dialog" aria-modal="true">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button onClick={() => setImageViewerModal(false)} type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="h-96 w-full overflow-hidden">
              <img src="sms-template.png" alt="s" class="h-full w-full object-scale-down" />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export {
  ImageViewerModal
}
