import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment-timezone";
// import { MenuAlt4Icon } from "@heroicons/react/outline";
import { TIME_ZONES } from "../utills/constant";
import Swal from "sweetalert2";
import { read, utils, writeFile } from 'xlsx';
// import { MenuAlt4Icon } from "@heroicons/react/outline";
import { InformationCircleIcon, InboxIcon, RefreshIcon, PaperAirplaneIcon } from "@heroicons/react/solid";
import {
  fetchDirectSmsList,
  fetchDirectSmsByDateList,
  saveParsedSmsList,
  fetchSMSConfigrationsList,
} from "../utills/network";
import { SmsParserModal } from "../shared/modals/sms-parser-modal";


export default function MessagesSMSList() {

  let [smsList, setSMSList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateSelectTerm, setDateSelectTerm] = useState("");
  const [smsParserModal, setSmsParserModal] = useState(false);
  const [selectTerm, setSelectTerm] = useState("");
  const [selectTypeTerm, setSelectTypeTerm] = useState("");
  const [smsTypeSelectTerm, setSMSTypeSelectTerm] = useState("");
  const [smsConfigurations, setSMSConfigurations] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState(null);
  let [selectedDate, setSelectedDate] = useState("");


  useEffect(() => {
    // getSMSList();
    getSMSTemplates();
  }, []);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSelectChange = (event) => {
    setSelectTerm(event.target.value);

  };
  const handleSelectTypeChange = (event) => {
    setSelectTypeTerm(event.target.value);

  };
  const handleSMSTypeSelectChange = (event) => {
    setSMSTypeSelectTerm(event.target.value);

  };
  const handleSelectTagTypeChange = (event) => {
    const object = smsConfigurations.find((data) => data._id === event.target.value);
    if (object) {
      setSelectedFormat(object._id);
    } else {
      setSelectedFormat(null);
    }
  };

  const getSMSTemplates = () => {
    fetchSMSConfigrationsList().then((result) => {
      if (result && result.success) {
        setSMSConfigurations(result.allSMSConfigrations);
      } else {
        Swal.fire("Alert", result.message, "warning");
      }
    });
  };

  const handleDateSelectChange = (event) => {
    console.log(event.target.value);
    selectedDate = moment(event.target.value, "YYYY-MM-DD").toISOString();
    setSelectedDate(selectedDate);
    setDateSelectTerm(event.target.value);
    callTripsApi();
  };

  const callTripsApi = () => {
    setSMSList([]);
    fetchDirectSmsByDateList(selectedDate).then((result) => {
      console.log(result);
      if (result && result.success) {
        smsList = result.allSms;
        setSMSList(result.allSms);
      } else {
        Swal.fire("Alert", result.message, "warning");
      }
    });
  }

  return (
    <div className="p-4  space-y-4 flex-1 overflow-y-auto">
      <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
        <div className="flex flex-wrap items-center space-y-2  sm:space-x-2">
          {smsList.length >0 && <div>
            <button
              onClick={()=> callTripsApi()}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
            >
              <RefreshIcon className=" h-5 w-5" />

            </button>
          </div>}
          <div className="relative">
            <input
              type="text"
              className="w-64 text-sm rounded"
              placeholder="Search ..."
              onChange={handleChange}
            />
          </div>
          <div className="relative ">
            <select
              className="w-64 text-sm rounded"
              onChange={handleSelectTagTypeChange}
            >
              <option key='Select SMS Body Format' value="">
                All
              </option>
              {smsConfigurations && smsConfigurations.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.title}
                </option>
              ))}

            </select>
          </div>

          <div className="relative ">
            <select
              className="w-64 text-sm rounded"
              onChange={handleSelectChange}
            >
              <option key="all" value="">All</option>
              <option key="sent" value="sent">Sent</option>
              <option key="received" value="received">Received</option>
            </select>
          </div>

          <div className="relative ">
            <select
              className="w-64 text-sm rounded"
              onChange={handleSelectTypeChange}
            >
              <option key="all" value="">All</option>
              <option key="mobile" value="mobile">Mobile</option>
              <option key="socket" value="socket">Socket</option>
              <option key="twilio" value="twilio">Twilio</option>
              <option key="vonage" value="vonage">Vonage</option>
              <option key="taxi-vonage" value="taxi-vonage">Taxi Vonage</option>
              <option key="unassign" value="unassign">Unassiged</option>
            </select>
          </div>



          <div className="relative ">
            <select
              className="w-64 text-sm rounded"
              onChange={handleSMSTypeSelectChange}
            >
              <option key="all" value="">All</option>
              <option key="single" value="single">Single</option>
              <option key="bulk" value="bulk">Bulk</option>
            </select>
          </div>
   


       
          <div className=" ">
            <input
              type="date"
              name="demoTime"
              id="demoTime"
              className="w-64 text-sm rounded"
              onChange={handleDateSelectChange}
            />
          </div>
          <div>
            <button
              type="button"
              onClick={() => setSmsParserModal(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
            >
              File Parser
            </button>
          </div>
          <div>
            <Link to="/new-direct-message">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
              >
                <InboxIcon className="-ml-1 mr-2 h-5 w-5" />
                New Message
              </button>
            </Link>
          </div>
          </div>
      </div>


      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border border-gray-100 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-100">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Name ({"0" && smsList
                        .filter(
                          (item) =>
                            (item.client.name && item.client.name
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())) ||
                            (item.client.phoneNumber && item.client.phoneNumber
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())) ||
                            item.status
                              .toLowerCase()
                              .includes(selectTerm.toLowerCase())
                        ).length})
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Tag
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Message Body
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Transporter Type
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Type
                    </th>
                    
                      <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Time
                    </th>
                      <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Status
                    </th>
                    {/* <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Action</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {smsList
                    .filter(
                      (item) =>
                        ((item.client.name && item.client.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())) ||
                          (item.client.phoneNumber && item.client.phoneNumber
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())))

                        && (
                          item.status
                            .toLowerCase() !== "" &&
                          item.status
                            .toLowerCase()
                            .includes(selectTerm.toLowerCase())
                        )
                        && (
                          item.type && item.type
                            .toLowerCase() !== "" &&
                          item.type
                            .toLowerCase()
                            .includes(smsTypeSelectTerm.toLowerCase())
                        )
                        && (
                          item.transporter && item.transporter
                            .toLowerCase() !== "" &&
                          item.transporter
                            .toLowerCase()
                            .includes(selectTypeTerm.toLowerCase()
                            
                          )
                        )
                    )
                    .filter(item => {
                      if (selectedFormat) {
                        if (item.tag && item.tag._id === selectedFormat) {
                          return item;
                        }
                      } else {
                        return item;
                      }
                    })
                    .map((sms) => (
                      <tr key={sms._id} className={sms.status === 'received' ? 'bg-yellow-100' : ''} >
                        <td className="px-6 py-4 w-40">
                          <div className="text-xs font-medium text-gray-900">
                            {sms.client.name}
                          </div>
                          <div className="text-xs text-gray-500">
                            {sms.client.phoneNumber}
                          </div>
                        </td>
                        <td className="px-6 py-4 w-64 text-xs text-gray-500 capitalize">
                          {sms.tag && <span class="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-1 text-xs font-medium text-indigo-800">{sms.tag.title}</span>}
                        </td>
                        <td className="py-4 flex w-72 flex-shrink-0 ">
                          <div className="text-xs text-gray-500">
                            {sms.body}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 capitalize">
                          {sms.transporter}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 capitalize">
                          {sms.type}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            <Moment format="YYYY-MM-DD HH:mm">
                              {sms.createdAt}
                            </Moment>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 capitalize">
                          {sms.status}
                        </td>
                        {/* <td className="px-6 py-4 whitespace-nowrap text-xs font-medium">
                          <a
                            href="/#"
                            className="text-red-600 hover:text-red-900"
                          >
                            Discard
                          </a>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {smsParserModal && <SmsParserModal smsParserModal={smsParserModal} setSmsParserModal={setSmsParserModal} />}
    </div>
  );
}



