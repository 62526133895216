import React, { useState, useEffect } from "react";
import { InformationCircleIcon, InboxIcon, PaperAirplaneIcon } from "@heroicons/react/solid";
import { read, utils, writeFile } from 'xlsx';
import {
  fetchDirectSmsList,
  fetchDirectSmsByDateList,
  saveParsedSmsList,
  fetchSMSConfigrationsList
} from "../../utills/network";
import Swal from "sweetalert2";
import { ImageViewerModal } from "../../shared/modals/image-viewer-modal";

const people = [
  { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
  // More people...
]

const SmsParserModal = ({ smsParserModal, setSmsParserModal }) => {
  const [selectTerm, setSelectTerm] = useState("");
  const [selectedFormat, setSelectedFormat] = useState("");
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [smsConfigurations, setSMSConfigurations] = useState([]);
  const [imageViewerModal, setImageViewerModal] = useState(false);

  useEffect(() => {
    // getSMSList();
    getSMSTemplates();
  }, []);


  const handleFileChange = (event) => {
    setSelectedFileData(null);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = read(event.target.result, { raw: true });
      const sheets = wb.SheetNames;
      if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, blankrows: false, raw: false });
        console.log(rows)
        setSelectedFileData(rows);
      }
    }
    reader.readAsArrayBuffer(file);

  };

  const handleImport = () => {
    if (selectedFormat === '') {
      Swal.fire("Alert", 'Select SMS Body Format', "warning");
      return;
    }

    let notValidFormat = false

    selectedFileData.forEach(item => {
      if (item.length < 4 || item[1] === "") {
        notValidFormat = true;
      }

    });

    if (notValidFormat) {
      Swal.fire("Alert", 'Format is not good', "warning");
      return;
    }

    saveParsedSmsList({ format: selectedFormat, passengers: selectedFileData }).then((result) => {
      setSelectedFileData(null);
      if (result && result.success) {
        console.log(result);
        Swal.fire("Alert", result.message, "success");
      } else {
        Swal.fire("Alert", result.message, "warning");
      }
    });

  }

  const handleSelectChange = (event) => {
    console.log(event.target.value);
    const object = smsConfigurations.find((data) => data._id === event.target.value);
    setSelectedFormat(object);
    console.log(object);
  };

  const getSMSTemplates = () => {
    fetchSMSConfigrationsList().then((result) => {
      if (result && result.success) {
        setSMSConfigurations(result.allSMSConfigrations);
      } else {
        Swal.fire("Alert", result.message, "warning");
      }
    });
  };


  return (
    <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button onClick={() => setSmsParserModal(false)} type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="h-auto w-full overflow-hidden">
              <div className="hidden py-8 md:flex justify-between items-center space-y-2  md:space-y-0">
                <div className="sm:flex items-center space-y-2 sm:space-y-0 sm:space-x-2">
                  <div className="relative">
                    <select
                      name="timeZone"
                      id="timeZone"
                      className={`w-full text-sm rounded`}
                      onChange={handleSelectChange}
                    >
                      <option key='Select SMS Body Format' value=''>
                        Select SMS Body Format
                      </option>
                      {smsConfigurations.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => setImageViewerModal(true)}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                  >
                    <InformationCircleIcon className=" h-5 w-5" />
                  </button></div>
                <div className="flex items-center space-x-2">
                  <div className=" ">
                    <input
                      type="file"
                      name="file"
                      id="file"
                      className="file-style"
                      onChange={handleFileChange}
                    />
                  </div>
                  {selectedFileData && <div>
                    <button
                      type="button"
                      onClick={() => handleImport()}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                    >
                      <PaperAirplaneIcon className="-ml-1 mr-2 h-5 w-5" />
                      Send SMS
                    </button>
                  </div>}
                </div>
              </div>
              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              # ({"0" && selectedFileData && selectedFileData.length})
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Name
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Phone Number
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Pickup Address
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Pickup Time
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {selectedFileData && selectedFileData.map((item,index) => (item.length >= 4 ? (
                            <tr key={item[0]}>

                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {index + 1}
                              </td>  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {item[0]}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item[1]}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item[2]}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item[3]}</td>
                            </tr>
                          ) :  <span class="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">Format is not good</span>))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {imageViewerModal && <ImageViewerModal imageViewerModal={imageViewerModal} setImageViewerModal={setImageViewerModal} />}
    </div>

  );
}

export {
  SmsParserModal
}
