import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/common/nav/Navbar";
import MessageSMSForm from "./components/direct-messages/message-sms-form";
import MessageForm from "./components/schedule-messages/message-form";
import MessagesSMSList from "./components/direct-messages/message-sms-list";
import MessagesList from "./components/schedule-messages/messages-list";
import MessagesLogs from "./components/provider-messages/messages-logs";
import Signin from "./components/auth/signin";
import Profile from "./components/profile/profile";
import EmailList from "./components/direct-e-mail/e-mail-list";
import EmailConfigurationsList from "./components/email-configurations/email-configurations-list";
import EmailConfigurationsForm from "./components/email-configurations/email-configurations-form";
import EmailForm from "./components/direct-e-mail/e-mail-form";
import EmailCampaignForm from "./components/email-campaign/email-campaign-form";
import EmailCampaignList from "./components/email-campaign/email-campaign-list";
import NoMatch from "./components/common/404/NoMatch";
import useToken from "./customHook/useToken";
import fetchIntercept from "fetch-intercept";
import { MenuAlt4Icon } from "@heroicons/react/outline";
import { validateAuthToken } from "./components/utills/network";
import { messageService } from "./components/utills/shared.service";
import LoadingBar from "react-top-loading-bar";
import Overlay from "./components/common/overlay/Overlay";
import SmsConfigurationsList from "./components/sms-configurations/sms-configurations-list";
import SmsConfigurationsForm from "./components/sms-configurations/sms-configurations-form";

import "./App.css";

export default function App() {
  const ref = useRef(null);
  const { token, setToken } = useToken();
  const [isShowNav, setShowNav] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleToggleClick = () => setShowNav((value) => !value);

  fetchIntercept.register({
    request: function (url, config) {
      startLoader();
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      return [url, config];
    },

    requestError: function (error) {
      return Promise.reject(error);
    },

    response: function (response) {
      stopLoader();
      return response;
    },

    responseError: function (error) {
      return Promise.reject(error);
    },
  });

  const startLoader = async () => {
    if (ref.current) {
      ref.current.continuousStart();
      setShowOverlay(true);
    }
  };

  const stopLoader = async () => {
    if (ref.current) {
      ref.current.complete();
      setShowOverlay(false);
    }
  };

  const verifyToken = async () => {
    token &&
      validateAuthToken().then((result) => {
        if (result && result.success) {
          messageService.sendMessage(result.user);
        } else {
          setToken(null);
        }
      });
  };

  useEffect(() => {
    verifyToken();
  }, []);

  if (!token) {
    return <Signin setToken={setToken} />;
  }

  return (
    <Router>
      <div className="flex h-screen bg-white">
        <Navbar setToken={setToken} handleToggleClick={handleToggleClick} isShowNav={isShowNav} />
        <main className="flex flex-1 flex-col w-full overflow-hidden">
          <div className="h-16 flex-shrink-0 border-b flex justify-between items-center w-full bg-white">
            <div className="font-medium text-lg text-gray-900 tracking-wide px-4">
              Dashboard
            </div>
            <div className="h-full lg:hidden">
              <button
                onClick={() => handleToggleClick()}
                className="h-full flex justify-center items-center w-12 focus:outline-none outline-none hover:bg-gray-200"
              >
                <MenuAlt4Icon className="h-6 w-6" />
              </button>
            </div>
          </div>
          <LoadingBar color="#809AE7" height={4} ref={ref} />
          <Overlay show={showOverlay} />
          <Switch className="p-8 m-8">
            <Route exact path="/schedule-message">
              <MessagesList />
            </Route>
            <Route exact path="/new-direct-message">
              <MessageSMSForm />
            </Route>
            <Route exact path="/">
              <MessagesSMSList />
            </Route>

            <Route exact path="/new-email-campaign">
              <EmailCampaignForm />
            </Route>
            <Route exact path="/email-campaigns">
              <EmailCampaignList />
            </Route>


            <Route exact path="/new-schedule-message">
              <MessageForm />
            </Route>
            <Route exact path="/new-direct-email">
              <EmailForm />
            </Route>
            <Route exact path="/direct-email">
              <EmailList />
            </Route>
            <Route exact path="/new-email-configurations">
              <EmailConfigurationsForm />
            </Route>
            <Route exact path="/email-configurations-list">
              <EmailConfigurationsList />
            </Route>
            <Route exact path="/new-sms-configurations">
              <SmsConfigurationsForm />
            </Route>
            <Route exact path="/new-sms-configurations/:id">
              <SmsConfigurationsForm />
            </Route>
            <Route exact path="/sms-configurations-list">
              <SmsConfigurationsList />
            </Route>
            
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/messages-logs">
              <MessagesLogs />
            </Route>
            <Route>
              <NoMatch />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
}
