import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CheckIcon } from "@heroicons/react/outline";
import Swal from "sweetalert2";
import { messageService } from "./../utills/shared.service";
import { io } from "socket.io-client";
import Constant from "../utills/constant";
import Moment from "react-moment";

import {
  updateConstants,
  fetchConstantsLatestList3,
  fetchConstantsLatestList4,
  fetchConstantsLatestList,
  fetchBatteryLevel,
  saveConstants,
  saveConstants3,
  saveConstants4,
  changePassword,
  fetchConstantsList,
} from "./../utills/network";

function Profile() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const ENDPOINT = Constant.MAIN_SERVER_URL;

  const {
    watch: watch2,
    register: register2,
    setValue: setValue2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({ defaultValues: { transporterType: "auto" } });
  const watchTransporterType = watch2("transporterType");
  const watch2Object = watch2();


  const {
    watch: watch3,
    register: register3,
    setValue: setValue3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm({ defaultValues: { transporterType: "auto" } });
  const watchTransporterType3 = watch3("transporterType");
  const watch3Object = watch3();


  const {
    watch: watch4,
    register: register4,
    setValue: setValue4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm({ defaultValues: { transporterType: "auto" } });
  const watchTransporterType4 = watch4("transporterType");
  const watch4Object = watch4();


  const [user, setUser] = useState(null);
  const [socketUsers, setSocketUsers] = useState(null);
  const [constantsList, setConstantsList] = useState([]);
  const [batteryLevel, setBatteryLevel] = useState({ key: '', value: '' });

  useEffect(() => {
    const socket = io(ENDPOINT, {
      query: {
        token: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    // added listener for socket Room users 
    socket.on("roomConnections", function (event) {
      console.log("userList ", event);
      setSocketUsers(event);
    });
    // first emmit for getting  Room users list
    // roomName: smsAlertRoom smsAlertRoomTest
    socket.emit("roomConnections", {
      roomName: "smsAlertRoom",
    });

    getLatestConstant();
    geAllConstantLogs();
    const subscription = messageService.getMessage().subscribe((message) => {
      if (message) {
        setUser(message);
      } else {
        setUser(null);
      }
    });

    return () => {
      console.log("Profile Destroy");
      subscription.unsubscribe();
    };
  }, []);

  const getLatestConstant = () => {
    fetchConstantsLatestList().then((constats) => {
      if (constats && constats.all) {
        setValue2("transporterType", constats.all[0].transporterType);
        if (constats.all[0].token) {
          setValue2("token", constats.all[0].token);
          const decodedToken = decodeJWT(constats.all[0].token.split(' ')[1]);
          if (decodedToken && decodedToken.payload && decodedToken.payload.exp) {
            const expTime = convertExpToReadable(decodedToken.payload.exp);
            console.log('JWT Expiration Time:', expTime);
            setValue2("tokenLabel", expTime);
          } else {
            console.error('Invalid or missing expiration time in JWT.');
            setValue2("tokenLabel", '');
          }
        }
        if (constats.all[0].phone)
          setValue2("phone", constats.all[0].phone);
        if (constats.all[0].url)
          setValue2("url", constats.all[0].url);
      } else {
      }
    });

    fetchConstantsLatestList3().then((constats) => {
      if (constats && constats.all) {
        setValue3("transporterType", constats.all[0].transporterType);
        if (constats.all[0].token) {
          setValue3("token", constats.all[0].token);
          const decodedToken = decodeJWT(constats.all[0].token.split(' ')[1]);
          if (decodedToken && decodedToken.payload && decodedToken.payload.exp) {
            const expTime = convertExpToReadable(decodedToken.payload.exp);
            console.log('JWT Expiration Time:', expTime);
            setValue3("tokenLabel", expTime);
          } else {
            console.error('Invalid or missing expiration time in JWT.');
            setValue3("tokenLabel", '');
          }

        }
        if (constats.all[0].phone)
          setValue3("phone", constats.all[0].phone);
        if (constats.all[0].url)
          setValue3("url", constats.all[0].url);
      } else {
      }
    });

    fetchConstantsLatestList4().then((constats) => {
      if (constats && constats.all) {
        setValue4("transporterType", constats.all[0].transporterType);
        if (constats.all[0].token) {
          setValue4("token", constats.all[0].token);
          const decodedToken = decodeJWT(constats.all[0].token.split(' ')[1]);
          if (decodedToken && decodedToken.payload && decodedToken.payload.exp) {
            const expTime = convertExpToReadable(decodedToken.payload.exp);
            console.log('JWT Expiration Time:', expTime);
            setValue4("tokenLabel", expTime);
          } else {
            console.error('Invalid or missing expiration time in JWT.');
            setValue4("tokenLabel", '');
          }

        }
        if (constats.all[0].phone)
          setValue4("phone", constats.all[0].phone);
        if (constats.all[0].url)
          setValue4("url", constats.all[0].url);
      } else {
      }
    });
  };

  const checkBatteryLevel = (email) => {
    fetchBatteryLevel(email).then((res) => {
      setBatteryLevel({ key: res.email, value: res.batteryLevel + "%" });
    });
  };

  function convertExpToReadable(exp) {
    // Convert Unix timestamp (seconds) to milliseconds
    const expMilliseconds = exp * 1000;

    // Create a new Date object using the milliseconds
    const expDate = new Date(expMilliseconds);

    // Return the formatted date and time
    return expDate.toUTCString(); // Adjust to your preferred format if needed
  }



  const geAllConstantLogs = () => {
    fetchConstantsList().then((constats) => {
      if (constats && constats.all) {
        setConstantsList(constats.all);
      } else {
      }
    });
  };

  const substringId = (str) => {
    return str.substring(str.length - 8, str.length);
  };

  const onSubmit = (data) => {
    console.log("Form: ", data);
    changePassword(data).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Alert!",
          text: res.message,
          icon: "success",
          confirmButtonColor: "#323484",
        });
        reset();
      } else {
        Swal.fire({
          title: "Alert!",
          text: res.message,
          icon: "warning",
          confirmButtonColor: "#323484",
        });
      }
    });
  };

  const onSubmitForm2 = (data) => {
    console.log("Form2: ", data);
    setConstantsList([]);
    saveConstants(data).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Alert!",
          text: res.message,
          icon: "success",
          confirmButtonColor: "#323484",
        });
        reset();
        geAllConstantLogs();
      } else {
        Swal.fire({
          title: "Alert!",
          text: res.message,
          icon: "warning",
          confirmButtonColor: "#323484",
        });
      }
    });
  };
  const onSubmitForm3 = (data) => {
    console.log("Form3: ", data);
    setConstantsList([]);
    saveConstants3(data).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Alert!",
          text: res.message,
          icon: "success",
          confirmButtonColor: "#323484",
        });
        reset();
        geAllConstantLogs();
      } else {
        Swal.fire({
          title: "Alert!",
          text: res.message,
          icon: "warning",
          confirmButtonColor: "#323484",
        });
      }
    });
  };

  const onSubmitForm4 = (data) => {
    console.log("Form4: ", data);
    setConstantsList([]);
    saveConstants4(data).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Alert!",
          text: res.message,
          icon: "success",
          confirmButtonColor: "#323484",
        });
        reset();
        geAllConstantLogs();
      } else {
        Swal.fire({
          title: "Alert!",
          text: res.message,
          icon: "warning",
          confirmButtonColor: "#323484",
        });
      }
    });
  };


  function decodeJWT(token) {
    // Split the token into header, payload, and signature
    const [headerEncoded, payloadEncoded, signature] = token.split('.');

    // Decode the header and payload from base64
    const header = JSON.parse(atob(headerEncoded));
    const payload = JSON.parse(atob(payloadEncoded));

    // Return an object containing the decoded header, payload, and signature
    return {
      header,
      payload,
      signature,
    };
  }

  return (
    <div className="p-4  space-y-4 flex-1 flex-col overflow-y-auto">
      <div>
        <div className="max-w-6xl rounded-lg border shadow-lg">
          <div className="h-48 bg-theme1 rounded-t-lg bg-profileBackgroundimg "></div>

          <div className="divide-y">
            <div className="flex justify-between p-6">
              <div className="flex flex-col items-center -mt-20">
                <div className="rounded-full h-24 w-24 p-1 bg-white bg-gradient-to-t to-indigo-500 from-red-500">
                  <img
                    src={
                      user && user?.profileImage !== ""
                        ? user.profileImage
                        : "person.jpg"
                    }
                    alt="user"
                    className="h-full w-full rounded-full object-cover"
                  />
                </div>
                {/* <div className="font-bold">{user?.displayName}</div> */}
              </div>
            </div>

            <div className="grid grid-cols-12 gap-5 px-4 py-8">
              <div className="col-span-12">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  NEMT SMS Setting
                </h3>
              </div>
              <form
                onSubmit={handleSubmit2(onSubmitForm2)}
                className="col-span-12"
              >
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 space-y-1">
                    <div className="space-x-4 flex items-start ">
                      <input
                        type="radio"
                        value="auto"
                        id="auto"
                        className={`${errors2.transporterType ? "ring-2 ring-red-500" : ""
                          } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                        {...register2("transporterType")}
                      />
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="auto"
                          className="font-medium text-gray-700"
                        >
                          Auto
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 space-y-1">
                    <div className="space-x-4  flex items-start ">
                      <input
                        type="radio"
                        value="socket"
                        id="socket"
                        className={`${errors2.transporterType ? "ring-2 ring-red-500" : ""
                          } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                        {...register2("transporterType")}
                      />
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="socket"
                          className="font-medium text-gray-700"
                        >
                          Socket
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 space-y-1">
                    <div className="space-x-4  flex items-start ">
                      <input
                        type="radio"
                        value="twilio"
                        id="twilio"
                        className={`${errors2.transporterType ? "ring-2 ring-red-500" : ""
                          } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                        {...register2("transporterType")}
                      />
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="twilio"
                          className="font-medium text-gray-700"
                        >
                          Twilio
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className=" space-x-4 col-span-12 space-y-1 items-center">
                    <div className="space-x-4  space-y-2 ">
                      <div className="space-x-4  space-y-2 ">
                        <div className="flex items-start ">
                          <input
                            type="radio"
                            value="vonage"
                            id="vonage"
                            className={`${errors2.transporterType ? "ring-2 ring-red-500" : ""
                              } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                            {...register2("transporterType")}
                          />
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="vonage"
                              className="font-medium text-gray-700"
                            >
                              <div> Vonage(<span className="text-sm text-gray-500">NEMT</span>)</div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className=" flex flex-col items-start ">
                        Url
                        <input
                          type="text"
                          id="url"
                          // placeholder="url"
                          className={`${errors2.url ? "ring-2 ring-red-500" : ""
                            }  w-full text-sm rounded`}
                          {...register2("url", { required: watchTransporterType === "vonage" ? true : false })}
                        />

                        {/* <div className="ml-3 text-sm">
                        <label
                          htmlFor="vonage"
                          className="font-medium text-gray-700"
                        >
                          Vonage
                        </label>
                      </div> */}
                      </div>
                      <div className="flex flex-col items-start ">
                        <div className="flex space-x-2">
                          <div>Token</div>
                          <div className="text-red-800">(Expire At:  {watch2Object.tokenLabel})</div>
                        </div>
                        <input
                          type="text"
                          id="token"
                          // placeholder="Token"
                          className={`${errors2.token ? "ring-2 ring-red-500" : ""
                            }  w-full text-sm rounded`}
                          {...register2("token", { required: watchTransporterType === "vonage" ? true : false })}
                        />

                        {/* <div className="ml-3 text-sm">
                        <label
                          htmlFor="vonage"
                          className="font-medium text-gray-700"
                        >
                          Vonage
                        </label>
                      </div> */}
                      </div>
                      <div className="flex flex-col items-start ">
                        <div>
                          <div>Phone (<span className="text-sm text-gray-500">At least 1</span>)</div>
                        </div>
                        <input
                          type="text"
                          id="phone"
                          // placeholder="Phone"
                          className={`${errors2.phone ? "ring-2 ring-red-500" : ""
                            }  w-full text-sm rounded`}
                          {...register2("phone", { required: watchTransporterType === "vonage" ? true : false })}
                        />

                        {/* <div className="ml-3 text-sm">
                        <label
                          htmlFor="vonage"
                          className="font-medium text-gray-700"
                        >
                          Vonage
                        </label>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 space-y-1 flex justify-end">
                    <button
                      type="submit"
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="grid grid-cols-12 gap-5 px-4 py-8">
              <div className="col-span-12">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Ride United SMS Setting
                </h3>
              </div>
              <form
                onSubmit={handleSubmit3(onSubmitForm3)}
                className="col-span-12"
              >
                <div className="grid grid-cols-12 gap-5">

                  <div className="col-span-12 space-y-1">
                    <div className="space-x-4 flex items-start ">
                      <input
                        type="radio"
                        value="auto"
                        id="auto"
                        className={`${errors3.transporterType ? "ring-2 ring-red-500" : ""
                          } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                        {...register3("transporterType")}
                      />
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="auto"
                          className="font-medium text-gray-700"
                        >
                          Auto
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 space-y-1">
                    <div className="space-x-4  flex items-start ">
                      <input
                        type="radio"
                        value="socket"
                        id="socket"
                        className={`${errors3.transporterType ? "ring-2 ring-red-500" : ""
                          } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                        {...register3("transporterType")}
                      />
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="socket"
                          className="font-medium text-gray-700"
                        >
                          Socket
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 space-y-1">
                    <div className="space-x-4  flex items-start ">
                      <input
                        type="radio"
                        value="twilio"
                        id="twilio"
                        className={`${errors3.transporterType ? "ring-2 ring-red-500" : ""
                          } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                        {...register3("transporterType")}
                      />
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="twilio"
                          className="font-medium text-gray-700"
                        >
                          Twilio
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className=" space-x-4 col-span-12 space-y-1 items-center">
                    <div className="space-x-4  space-y-2 ">
                      <div className="space-x-4  space-y-2 ">
                        <div className="flex items-start ">
                          <input
                            type="radio"
                            value="vonage"
                            id="vonage"
                            className={`${errors3.transporterType ? "ring-2 ring-red-500" : ""
                              } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                            {...register3("transporterType")}
                          />
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="vonage"
                              className="font-medium text-gray-700"
                            >
                              <div> Vonage(<span className="text-sm text-gray-500">Ride United</span>)</div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className=" flex flex-col items-start ">
                        Url
                        <input
                          type="text"
                          id="url"
                          // placeholder="url"
                          className={`${errors3.url ? "ring-2 ring-red-500" : ""
                            }  w-full text-sm rounded`}
                          {...register3("url", { required: watchTransporterType3 === "vonage" ? true : false })}
                        />

                        {/* <div className="ml-3 text-sm">
                        <label
                          htmlFor="vonage"
                          className="font-medium text-gray-700"
                        >
                          Vonage
                        </label>
                      </div> */}
                      </div>
                      <div className="flex flex-col items-start ">
                        <div className="flex space-x-2">
                          <div>Token</div>
                          <div className="text-red-800">(Expire At:  {watch3Object.tokenLabel})</div>
                        </div>
                        <input
                          type="text"
                          id="token"
                          // placeholder="Token"
                          className={`${errors3.token ? "ring-2 ring-red-500" : ""
                            }  w-full text-sm rounded`}
                          {...register3("token", { required: watchTransporterType3 === "vonage" ? true : false })}
                        />

                        {/* <div className="ml-3 text-sm">
                        <label
                          htmlFor="vonage"
                          className="font-medium text-gray-700"
                        >
                          Vonage
                        </label>
                      </div> */}
                      </div>
                      <div className="flex flex-col items-start ">
                        <div>
                          <div>Phone (<span className="text-sm text-gray-500">At least 1</span>)</div>
                        </div>
                        <input
                          type="text"
                          id="phone"
                          // placeholder="Phone"
                          className={`${errors3.phone ? "ring-2 ring-red-500" : ""
                            }  w-full text-sm rounded`}
                          {...register3("phone", { required: watchTransporterType3 === "vonage" ? true : false })}
                        />

                        {/* <div className="ml-3 text-sm">
                        <label
                          htmlFor="vonage"
                          className="font-medium text-gray-700"
                        >
                          Vonage
                        </label>
                      </div> */}
                      </div>
                    </div>
                  </div>


                  <div className="col-span-12 space-y-1 flex justify-end">
                    <button
                      type="submit"
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                    >
                      {/* <CheckIcon className="-ml-1 mr-2 h-5 w-5" /> */}
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>

            
            <div className="grid grid-cols-12 gap-5 px-4 py-8">
              <div className="col-span-12">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  AWMT SMS Setting
                </h3>
              </div>
              <form
                onSubmit={handleSubmit4(onSubmitForm4)}
                className="col-span-12"
              >
                <div className="grid grid-cols-12 gap-5">

                  <div className="col-span-12 space-y-1">
                    <div className="space-x-4 flex items-start ">
                      <input
                        type="radio"
                        value="auto"
                        id="auto"
                        className={`${errors4.transporterType ? "ring-2 ring-red-500" : ""
                          } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                        {...register4("transporterType")}
                      />
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="auto"
                          className="font-medium text-gray-700"
                        >
                          Auto
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 space-y-1">
                    <div className="space-x-4  flex items-start ">
                      <input
                        type="radio"
                        value="socket"
                        id="socket"
                        className={`${errors4.transporterType ? "ring-2 ring-red-500" : ""
                          } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                        {...register4("transporterType")}
                      />
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="socket"
                          className="font-medium text-gray-700"
                        >
                          Socket
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 space-y-1">
                    <div className="space-x-4  flex items-start ">
                      <input
                        type="radio"
                        value="twilio"
                        id="twilio"
                        className={`${errors4.transporterType ? "ring-2 ring-red-500" : ""
                          } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                        {...register4("transporterType")}
                      />
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="twilio"
                          className="font-medium text-gray-700"
                        >
                          Twilio
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className=" space-x-4 col-span-12 space-y-1 items-center">
                    <div className="space-x-4  space-y-2 ">
                      <div className="space-x-4  space-y-2 ">
                        <div className="flex items-start ">
                          <input
                            type="radio"
                            value="vonage"
                            id="vonage"
                            className={`${errors4.transporterType ? "ring-2 ring-red-500" : ""
                              } focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-0.5`}
                            {...register4("transporterType")}
                          />
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="vonage"
                              className="font-medium text-gray-700"
                            >
                              <div> Vonage(<span className="text-sm text-gray-500">AWMT</span>)</div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className=" flex flex-col items-start ">
                        Url
                        <input
                          type="text"
                          id="url"
                          // placeholder="url"
                          className={`${errors4.url ? "ring-2 ring-red-500" : ""
                            }  w-full text-sm rounded`}
                          {...register4("url", { required: watchTransporterType4 === "vonage" ? true : false })}
                        />

                        {/* <div className="ml-3 text-sm">
                        <label
                          htmlFor="vonage"
                          className="font-medium text-gray-700"
                        >
                          Vonage
                        </label>
                      </div> */}
                      </div>
                      <div className="flex flex-col items-start ">
                        <div className="flex space-x-2">
                          <div>Token</div>
                          <div className="text-red-800">(Expire At:  {watch4Object.tokenLabel})</div>
                        </div>
                        <input
                          type="text"
                          id="token"
                          // placeholder="Token"
                          className={`${errors4.token ? "ring-2 ring-red-500" : ""
                            }  w-full text-sm rounded`}
                          {...register4("token", { required: watchTransporterType4 === "vonage" ? true : false })}
                        />

                        {/* <div className="ml-3 text-sm">
                        <label
                          htmlFor="vonage"
                          className="font-medium text-gray-700"
                        >
                          Vonage
                        </label>
                      </div> */}
                      </div>
                      <div className="flex flex-col items-start ">
                        <div>
                          <div>Phone (<span className="text-sm text-gray-500">At least 1</span>)</div>
                        </div>
                        <input
                          type="text"
                          id="phone"
                          // placeholder="Phone"
                          className={`${errors4.phone ? "ring-2 ring-red-500" : ""
                            }  w-full text-sm rounded`}
                          {...register4("phone", { required: watchTransporterType4 === "vonage" ? true : false })}
                        />

                        {/* <div className="ml-3 text-sm">
                        <label
                          htmlFor="vonage"
                          className="font-medium text-gray-700"
                        >
                          Vonage
                        </label>
                      </div> */}
                      </div>
                    </div>
                  </div>


                  <div className="col-span-12 space-y-1 flex justify-end">
                    <button
                      type="submit"
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                    >
                      {/* <CheckIcon className="-ml-1 mr-2 h-5 w-5" /> */}
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>






            <div className="grid grid-cols-12 gap-5 px-4 py-8">
              <div className="col-span-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Socket Logs
                </h3>
              </div>
              <div className="col-span-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Connected User
                </h3>
              </div>
              <div className="col-span-6">
                <div className="flex flex-col">
                  <div className="-my-2 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                id
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Title
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Updated At
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200 h-max-24 overflow-auto">
                            {constantsList.map((constats) => (
                              <tr key={constats._id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                                  {substringId(constats._id)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {constats.transporterType}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  <Moment format="YYYY-MM-DD HH:mm">
                                    {constats.createdAt}
                                  </Moment>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-6">
                <div className="flex flex-col">
                  <div className="-my-2 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                id
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Battery
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200 h-max-24 overflow-auto">
                            {socketUsers &&
                              socketUsers.smsAlertRoom &&
                              socketUsers.smsAlertRoom.map((user) => (
                                <tr key={user._id}>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                                    {substringId(user._id)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {user.displayName}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {user.email}
                                  </td>
                                  <td className="cursor-pointer text-blue-500 px-6 py-4 whitespace-nowrap text-sm " onClick={() => checkBatteryLevel(user.email)}>
                                    <span>{batteryLevel.key === user.email ? batteryLevel.value : 'Fetch'}</span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <form onSubmit={handleSubmit(onSubmit)} className="col-span-1">
              <div className="grid sm:grid-cols-2 gap-5 p-6">
                <div className="space-y-1">
                  <div className="text-sm text-gray-500">Name</div>
                  <div className="text-gray-700">{user?.displayName}</div>
                </div>
                <div className="space-y-1">
                  <div className="text-sm text-gray-500">Email</div>
                  <div className="text-gray-700">{user?.email}</div>
                </div>
                <div className="space-y-1">
                  <div className="text-sm text-gray-500">Role</div>
                  <div className="text-gray-700">{user?.role}</div>
                </div>
                <div className="space-y-1">
                  <div className="text-sm text-gray-500">TimeZone</div>
                  <div className="text-gray-700">{user?.timeZone}</div>
                </div>
              </div>
              <div className="grid sm:grid-cols-2 gap-5 p-6">
                <div className="relative space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm text-gray-500"
                  >
                    Password
                  </label>
                  <input
                    name="password"
                    type="password"
                    id="password"
                    className={`${errors.password ? "ring-2 ring-red-500" : ""
                      } w-full text-sm rounded`}
                    {...register("password", { required: true })}
                  />
                </div>
                <div className="relative space-y-1">
                  <label
                    htmlFor="newPassword"
                    className="block text-sm text-gray-500"
                  >
                    New Password
                  </label>
                  <input
                    name="newPassword"
                    type="password"
                    id="newPassword"
                    className={`${errors.newPassword ? "ring-2 ring-red-500" : ""
                      } w-full text-sm rounded`}
                    {...register("newPassword", { required: true })}
                  />
                </div>

                <div className="relative space-y-1">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm text-gray-500"
                  >
                    Confirm Password
                  </label>
                  <input
                    name="confirmPassword"
                    type="password"
                    id="confirmPassword"
                    className={`${errors.confirmPassword ? "ring-2 ring-red-500" : ""
                      } w-full text-sm rounded`}
                    {...register("confirmPassword", { required: true })}
                  />
                </div>
                <div className="col-span-2">
                  <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-theme1 hover:bg-theme1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme1"
                  >
                    <CheckIcon className="-ml-1 mr-2 h-5 w-5" />
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
